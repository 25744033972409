<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <title-text />
        </b-col>
        <b-col>
          <phone-number />
        </b-col>
        <b-col>
          <fax-number />
        </b-col>
        <b-col>
          <mobile-number />
        </b-col>
        <b-col cols="12">
          <email-address />
        </b-col>
        <b-col cols="12">
          <address-text />
        </b-col>
      </b-row>
    </b-card>
    <location-map />
  </div>
</template>
<script>
import {
  BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'
import TitleText from '@/views/Admin/Contacts/elements/TitleText.vue'
import PhoneNumber from '@/views/Admin/Contacts/elements/PhoneNumber.vue'
import FaxNumber from '@/views/Admin/Contacts/elements/FaxNumber.vue'
import MobileNumber from '@/views/Admin/Contacts/elements/MobileNumber.vue'
import EmailAddress from '@/views/Admin/Contacts/elements/EmailAddress.vue'
import AddressText from '@/views/Admin/Contacts/elements/AddressText.vue'
import LocationMap from '@/views/Admin/Contacts/LocationMap.vue'

export default {
  name: 'ContactForm',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    TitleText,
    PhoneNumber,
    FaxNumber,
    MobileNumber,
    EmailAddress,
    AddressText,
    LocationMap,
  },
  data() {
    return {
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['contacts/dataItem']
    },
  },
}
</script>
