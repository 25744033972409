<template>
  <b-form-group
    label="Başlık"
    label-for="title"
  >
    <b-form-input
      id="title"
      v-model="itemData.title"
      placeholder="Başlık"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'Title',
  components: { BFormGroup, BFormInput },
  computed: {
    itemData() {
      return this.$store.getters['contacts/dataItem']
    },
  },
}
</script>
