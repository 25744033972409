<template>
  <b-form-group
    label="Adres"
    label-for="address"
  >
    <b-form-textarea
      id="address"
      v-model="itemData.address"
      placeholder="Adres"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'Title',
  components: { BFormGroup, BFormTextarea },
  computed: {
    itemData() {
      return this.$store.getters['contacts/dataItem']
    },
  },
}
</script>
